import React from "react";
import { TopHeader } from "./TopHeader";

export const HeaderComplete = () => {
  return (
    <div>
      <TopHeader />
    </div>
  );
};
