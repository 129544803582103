import React from "react";

import LinkdinOff from "../svgs/LinkdinOff";
import Github from "../svgs/Github";

export const ContactPage = () => {
  return (
    <div className="min-h-screen bg-[#F5F5F5] flex flex-col lg:flex-row">
      <div className="  bg-[#F5F5F5] flex flex-col">
        <div className="flex flex-col lg:w-[700px] px-4 w-full">
          <div>
            <div className="font-bold text-[70px] gap-[30px] lg:ml-[220px] mt-[40px] animate-slideInLeft">
              <h1>contact</h1>
            </div>
            <div className="font-oswald bg-black text-[26px] p-[5px] lg:ml-[190px] inline-block gap-2">
              <p className="text-white m-0">
                <span>Get in touch with me via </span>
                <span className="text-purple-500">social media</span>
              </p>
            </div>
            <div className="flex flex-row gap-[30px] max-w-[565px] justify-center mt-4">
              <a href="https://github.com/Gorvintheone">
                <Github />
              </a>
              <a href="https://www.linkedin.com/in/bence-harmath-05373132b/">
                <LinkdinOff />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
