import React from "react";

const photosForComforty = [
  { id: 1, Photo: "./assets/comforty1.png" },
  { id: 2, Photo: "./assets/comforty2.png" },
  { id: 3, Photo: "./assets/comforty3.png" },
];

const photosForLeather = [
  { id: 1, Photo: "./assets/leather1.png" },
  { id: 2, Photo: "./assets/leather2.png" },
  { id: 3, Photo: "./assets/leather3.png" },
];

export const PortfolioPage = () => {
  return (
    <div className="min-h-screen flex flex-col bg-white">
      <div>
        <h1 className="font-bold text-[60px] ml-[120px] mt-[40px] animate-slideInLeft">
          portfolio
        </h1>
        <p className="ml-[60px] font-oswald text-[20px] mt-[20px] font-bold pr-[5px] animate-slideInLeft">
          Since I started learning this year, my portfolio is still quite small,
          but I am currently working on two projects.
        </p>
        <p className="ml-[80px] mt-[20px] font-oswald text-[19px] animate-slideInLeft">
          - One is a custom order for a Hungarian leather craftsman,
        </p>
        <p className="ml-[80px] mt-[20px] font-oswald text-[20px] animate-slideInLeft">
          - and the other is a project led by my mentor, where we are enhancing
          and expanding our knowledge of JavaScript and other
        </p>
      </div>

      <div className="bg-[#F5F5F5] border-t p-[40px] mt-[40px border-gray-300 animate-slideInLeft">
        <div className="font-oswald font-bold text-[30px] mb-[20px] text-center md:text-left">
          <h2>The Leather Crafts Site:</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-[15px] justify-center items-center">
          {photosForLeather.map((item, index) => (
            <div key={index} className="p-[15px] flex justify-center">
              <img
                className="w-full lg:w-[400px]  bg-slate-300"
                src={item.Photo}
                alt="web-fotos"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="bg-white border-t ] p-[40px] border-gray-300 animate-slideInRight">
        <div className="font-oswald font-bold text-[30px] mb-[20px] text-center md:text-left">
          <h2>The Mentor Site:</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-[15px] justify-center items-center">
          {photosForComforty.map((item, index) => (
            <div key={index} className="p-[15px] flex justify-center">
              <img
                className="w-full lg:w-[400px]  bg-slate-300"
                src={item.Photo}
                alt="web-fotos"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
