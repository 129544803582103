import * as React from "react";
const LinkdinOff = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={60}
    height={60}
    viewBox="-11.494 -16.312 99.612 97.871"
    {...props}
  >
    <path
      fill="#0a66c2"
      d="M72.865 61.11a1.2 1.2 0 0 0 1.21-1.27c0-.9-.543-1.33-1.657-1.33h-1.8v4.712h.677v-2.054h.832l.019.025 1.291 2.03h.724l-1.389-2.1zm-.783-.473h-.785v-1.593h.995c.514 0 1.1.084 1.1.757 0 .774-.593.836-1.314.836m-16.873-5.433h-9.6V40.17c0-3.585-.064-8.2-4.993-8.2-5 0-5.765 3.906-5.765 7.94v15.293h-9.6V24.287h9.216v4.225h.129a10.1 10.1 0 0 1 9.093-4.994c9.73 0 11.524 6.4 11.524 14.726zm-40.79-35.143a5.571 5.571 0 1 1 5.57-5.572 5.571 5.571 0 0 1-5.57 5.572m4.8 35.143h-9.61V24.287h9.61zM59.991.004H4.781A4.728 4.728 0 0 0 0 4.674v55.44a4.731 4.731 0 0 0 4.781 4.674h55.21a4.741 4.741 0 0 0 4.8-4.675V4.67a4.738 4.738 0 0 0-4.8-4.67"
    />
    <path
      fill="#0a66c2"
      d="M72.164 56.411a4.418 4.418 0 1 0 .085 0h-.085m0 8.33a3.874 3.874 0 1 1 3.809-3.938v.065a3.791 3.791 0 0 1-3.708 3.871h-.1"
    />
  </svg>
);
export default LinkdinOff;
