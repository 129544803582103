import React from "react";
import { Link, useLocation } from "react-router-dom";

const currentYear = new Date().getFullYear();

export const FooterTop = () => {
  const location = useLocation();
  const activeLink = location.pathname;
  return (
    <div className="w-full bg-[#F5F5F5] h-[60px] grid grid-cols-1  text-gray-400 flex-row justify-center items-cente lg:grid-cols-2 ">
      <div className="grid items-center justify-center">
        <p>©{currentYear} GorvinWs</p>
      </div>
      <div className="flex flex-row gap-[15px] items-center justify-center">
        <Link
          to="/about"
          className={` hover:text-black cursor-pointer ${
            activeLink === "/about" || activeLink === "/" ? "text-black" : ""
          }`}
        >
          about
        </Link>

        <Link
          to="/portfolio"
          className={` hover:text-black cursor-pointer ${
            activeLink === "/portfolio" ? "text-black" : ""
          }`}
        >
          portfolio
        </Link>
        <Link
          to="/contact"
          className={` hover:text-black cursor-pointer ${
            activeLink === "/contact" ? "text-black" : ""
          }`}
        >
          contact
        </Link>
      </div>
    </div>
  );
};
