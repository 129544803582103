import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { HeaderComplete } from "./components/header/HeaderComplete";
import { FooterComplete } from "./components/footer/FooterComplete";
import { AboutPage } from "./components/pages/AboutPage";
import { PortfolioPage } from "./components/pages/PortfolioPage";
import { ContactPage } from "./components/pages/ContactPage";

const App = () => {
  return (
    <Router>
      <div className="min-h-screen flex flex-col">
        <header>
          <HeaderComplete />
        </header>

        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<AboutPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/portfolio" element={<PortfolioPage />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </main>

        <footer>
          <FooterComplete />
        </footer>
      </div>  
    </Router>
  );
};

export default App;
