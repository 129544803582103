import React from "react";
import { FooterTop } from "./FooterTop";

export const FooterComplete = () => {
  return (
    <div>
      <FooterTop />
    </div>
  );
};
